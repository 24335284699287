<template>
  <div class="Reference">
    <NavBar />
    <!-- <OrderNavigation /> -->
    <h2>Заказы</h2>
    <v-autocomplete
      label="Выберите кафе"
      outlined
      v-model="station"
      item-value="token"
      item-text="nameExpFullRu"
      v-on:change="chooseStation"
      :items="stations"
    ></v-autocomplete>
    <div class="ReferenceOperatorMainframe">
      <div class="ReferenceUpBoard">
        <div v-if="currentCafe !== null">
          <div class="ReferenceProcessList">
            <div class="ReferenceProcessListNumber">
              {{ this.currentCafe.orders.length }}
            </div>
            <div class="VipProcessListText">
              {{ this.declOfNum(this.currentCafe.orders.length, ['заказ', 'заказа', 'заказов']) }}
              <v-spacer />
              всего
            </div>
          </div>
        </div>
        <div v-if="this.isOrdersLoading || this.isOrdersError || this.orderListByStations.length <= 0">
          <v-chip label text-color="white" color="#0066A1">
            {{ this.loadProgress }}
          </v-chip>
        </div>
      </div>
      <div>
        <ReferenceOperatorList v-if="currentStation !== null" :referenceList="this.currentStation" type="operator" />
      </div>
    </div>
    <NotificationContainer />
  </div>
</template>

<script>
import helper from '@/helpers/reference/reference.helper';
import ReferenceOperatorList from '@/components/reference/ReferenceOperatorList';
import NavBar from '@/components/NavBar';
// import OrderNavigation from '@/components/OrderNavigation';

import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import NotificationContainer from '@/components/notification/NotificationContainer.vue';

export default {
  data() {
    return {
      search: null,
      interval: null,
      countCurrentType: null,
      station: null,
      currentCafe: null
    };
  },
  components: {
    NotificationContainer,
    ReferenceOperatorList,
    NavBar
  },
  computed: {
    stations() {
      let stationsArr = [];
      if (this.orderListByStations !== undefined && this.orderListByStations !== null) {
        this.orderListByStations.forEach((item) => {
          stationsArr.push(item);
        });
      }
      return JSON.parse(JSON.stringify(stationsArr));
    },
    currentStation() {
      let orders = null;
      let station = null;
      if (this.station !== null && this.station.length > 0) {
        orders = [];
        station = this.orderListByStations.find((station) => station.token === this.station);
        station.takeouts.forEach((takeout) => takeout.orders.forEach((order) => orders.push(order)));
      }
      console.log(orders);
      return orders;
    },
    loadProgress() {
      if (this.isOrdersLoading) {
        return 'Загружаем список';
      } else if (this.isOrdersError) {
        return 'Ошибка загрузки списка: ' + this.ordersErrorMessage;
      } else if (!this.orderListByStations || this.orderListByStations.length <= 0) {
        return 'Список пуст';
      } else return 'Количество записей: ' + this.currentStation.length;
    },
    ...mapGetters('order', ['orderListByStations']),
    ...mapGetters('order', ['isOrdersLoading']),
    ...mapGetters('order', ['isOrdersError']),
    ...mapGetters('order', ['ordersErrorMessage']),
    ...mapGetters('order', ['orderListTimer'])
  },
  mounted() {
    this.updateReferenceList();
    this.interval = setInterval(() => {
      let isNeedToUpdate = false;
      try {
        if (this.orderListTimer === undefined || this.orderListTimer === null) {
          isNeedToUpdate = true;
        } else {
          if (this.orderListTimer.isBefore(moment().subtract(10, 'm'))) {
            isNeedToUpdate = true;
          }
        }
      } catch (ex) {
        console.log(ex);
        isNeedToUpdate = true;
      }
      if (isNeedToUpdate) {
        this.updateReferenceList();
      }
    }, 60000);
  },
  beforeDestroy() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  },
  methods: {
    chooseStation() {
      let orders = [];
      console.log(this.station);
      console.log(this.stations.find((station) => station.token === this.station));
      this.updateCourierList(this.station);
      this.stations.find((station) => station.token === this.station).takeouts.forEach((takeout) => takeout.orders.forEach((order) => orders.push(order)));
      // console.log(orders);
      return orders;
    },
    // getCafes() {
    //   let list = [];
    //   if (this.cafes !== undefined && this.cafes !== null && this.cafes.length >= 0) {
    //     for (let i = 0; i < this.cafes.length; i++) {
    //       list.push({ text: this.cafes[i].nameRu, value: this.cafes[i].token });
    //     }
    //   }
    //   return list;
    // },
    declOfNum: helper.declOfNum,
    updateReferenceList() {
      this.fetchOrdersByOperator();
    },
    updateCourierList(token) {
      this.fetchCouriersByStation(token);
    },
    ...mapActions('order', ['fetchOrdersByOperator']),
    ...mapActions('courier', ['fetchCouriersByStation'])
  }
};
</script>

<style>
.Reference {
  //background-color: blue;
}
.ReferenceUpBoard {
  min-height: 50px;
}
.ReferenceOperatorMainframe {
  //background-color: burlywood;
}
.ReferenceSearch {
  border-radius: 5px;
  color: white;
  font-weight: 400;
  box-shadow: 0 2px 2px #8eaadb;
  border: solid 1px #2f5496;
  margin: 20px 0 10px 0;
  padding: 2px;
}
.text-black input {
  color: black !important;
}
.ReferenceOperatorList {
  display: flex;
  align-items: center;
  margin: 20px 0 10px 0;
  font-weight: 500;
}
.ReferenceUploadButton {
  margin-left: auto;
}
.ReferenceProcessList {
  display: flex;
}
.ReferenceProcessListNumber {
  font-size: 50px;
  color: #e21a1a;
  padding-right: 10px;
  line-height: 1;
  font-weight: normal;
}
.ReferenceProcessListText {
  font-size: 14px;
  color: #8eaadb;
  margin-left: 16px;
}
</style>
